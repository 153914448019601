<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <night-light-manager
          class="full-height-card"
        />
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <reading-light-manager
          class="full-height-card"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <input-sound-manager
          class="full-height-card"
        />
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <output-sound-manager
          class="full-height-card"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <drink-reminder-configurator
          class="full-height-card"
        />
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <medication-reminder-configurator
          class="full-height-card"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <city-picker-weather
          class="full-height-card"
        />
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <emma-call-name-picker
          class="full-height-card"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <alarm-device-toggle
          class="full-height-card"
        />
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <call-type-toggle
          class="full-height-card"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <timer-manager />
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <alarmclock-manager
          class="full-height-card"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import NightLightManager from '@/components/emma/light/NightLightManager.vue'
import ReadingLightManager from '@/components/emma/light/ReadingLightManager.vue'
import InputSoundManager from '@/components/emma/sound/InputSoundManager.vue'
import OutputSoundManager from '@/components/emma/sound/OutputSoundManager.vue'
import DrinkReminderConfigurator from '@/components/emma/reminders/DrinkReminderConfigurator.vue'
import MedicationReminderConfigurator from '@/components/emma/reminders/MedicationReminderConfigurator.vue'
import AlarmclockManager from '@/components/emma/timer/AlarmclockManager.vue'
import TimerManager from '@/components/emma/timer/TimerManager.vue'
import AlarmDeviceToggle from '@/components/emma/calls/AlarmDeviceToggle.vue'
import CityPickerWeather from '@/components/emma/weather/CityPickerWeather.vue'
import CallTypeToggle from '@/components/emma/calls/CallTypeToggle.vue'
import EmmaCallNamePicker from '@/components/emma/identity/EmmaCallNamePicker.vue'

export default {
  name: 'EmmaControlDashboard',
  components: {
    BRow,
    BCol,
    NightLightManager,
    ReadingLightManager,
    InputSoundManager,
    OutputSoundManager,
    DrinkReminderConfigurator,
    MedicationReminderConfigurator,
    AlarmclockManager,
    TimerManager,
    AlarmDeviceToggle,
    CityPickerWeather,
    CallTypeToggle,
    EmmaCallNamePicker,
  },
  computed: {
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
    selectedEmmaSettings() {
      return this.$store.state.emmasettings.settings
    },
  },
}
</script>

<style scoped>
.full-height-card {
  height: calc(100% - 2rem);
}

</style>
