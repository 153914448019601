<template>
  <div>
    <b-card>
      <b-button
        v-b-modal.add-emma-contact-modal
        class="mb-1"
        variant="primary"
      >
        {{ $t('addNewContact') }}
      </b-button>
      <contact-person-table />
    </b-card>
    <add-emma-contact-modal />
  </div>
</template>

<script>
import ContactPersonTable from '@/components/emma/tables/ContactPersonTable.vue'
import AddEmmaContactModal from '@/components/forms/AddEmmaContactModal.vue'
import {
  BButton, BCard,
} from 'bootstrap-vue'

export default {
  name: 'ContactPersonConfigurator',
  components: {
    BButton, BCard, ContactPersonTable, AddEmmaContactModal,
  },
}
</script>

<style scoped>

</style>
