<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('microphoneVolume') }}
      </b-card-title>
    </b-card-header>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <b-input-group>
        <b-form-input
          id="bg-opacity"
          v-model="volumeMicrophone"
          lg
          type="range"
          number
          min="0"
          max="100"
          step="1"
          debounce="100"
          @update="setMicrophoneVolumeEmma"
        />
        <b-input-group-append
          is-text
          class="text-monospace"
        >
          {{ volumeMicrophone }}
        </b-input-group-append>
      </b-input-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'InputSoundManager',
  components: {
    BCard,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCardHeader,
    BCardTitle,
  },
  props: {
  },
  data() {
    return {
      volumeMicrophone: 100,
    }
  },
  mounted() {
    this.volumeMicrophone = this.$store.state.emmasettings.settings.micVolume.level
  },
  methods: {
    /**
     * Sets volume of emma to value set via slider
     * @returns {Promise<void>}
     */
    async setMicrophoneVolumeEmma() {
      await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/audio/set_mic_volume`, {
        volume: this.volumeMicrophone,
      })
    },
  },
}
</script>

<style scoped>

</style>
